export enum ErrorKey {
  LoanNotFound = 0,
  ActionNotCompleted = 1,
  CustomerNotFound = 2,
  SqlException = 3,
  DuplicateKey = 4,
  AccessTokenNotFound = 5,
  UnableToGetResponse = 6,
  PlaidApiException = 7,
  EpicError = 8,
  InvalidSignature = 9,
  AlreadySigned = 10,
  EmailMissMatch = 12,
  SameDayFunding = 17
}
